<template>
  <b-row>
    <b-col md="12" v-for="(category,index) in riskQuestionCategories" :key="index">
      <h3>{{ category }}</h3>
      <b-col md="12" v-for="(riskQuestion,index) in riskQuestions" :key="index">
        <div v-if="riskQuestion.category === category">
          <div
              v-if="!riskQuestion.conditional || (riskQuestion.conditionalQuestion === 'structure' && riskQuestion.conditionalValue.split(',').includes(structure)) ||
                (answers[riskQuestion.conditionalQuestion] &&
                (riskQuestion.conditionalType === 'equals_or' && riskQuestion.conditionalValue.split(',').includes(form[riskQuestion.conditionalQuestion])) ||
                (riskQuestion.conditionalType === 'equals' && riskQuestion.conditionalValue === form[riskQuestion.conditionalQuestion].answer) ||
                (riskQuestion.conditionalType === 'not_empty' && form[riskQuestion.conditionalQuestion].input))">
            <b-row>
              <b-col md="9">{{ riskQuestion.question }}</b-col>
              <b-col md="3" class="text-right cursor-pointer">
                <feather-icon icon="HelpCircleIcon" size="20" class="text-primary mb-1"
                              v-if="riskQuestion.helpText && !riskQuestion.showHelp"
                              @click="riskQuestion.showHelp = true"/>
                <feather-icon icon="ChevronUpIcon" size="20" class="text-primary mb-1"
                              v-if="riskQuestion.helpText && riskQuestion.showHelp"
                              @click="riskQuestion.showHelp = false"/>
              </b-col>
              <b-col md="12" v-if="riskQuestion.showHelp">
                <b-alert variant="dark" show>

                  <div class="alert-body">
                    <span v-html="riskQuestion.helpText"></span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <div v-if="riskQuestion.questionType === 'select'">
              <b-form-group class="mb-2">
                <v-select
                    v-model="form[riskQuestion.questionLabel]"
                    :options="riskQuestion.answers"
                    :id="riskQuestion.questionLabel"
                    label="answer"
                    item-text="answer"
                    return-object
                    attach
                    hide-details
                    @input="updateRisk(riskQuestion.questionLabel)"
                >
                </v-select>
              </b-form-group>

              <div v-if="form[riskQuestion.questionLabel]" class="mb-2">
                <div v-if="form[riskQuestion.questionLabel].fileRequired && form[riskQuestion.questionLabel].fileUrl">
                  <span class="cursor-pointer"><feather-icon icon="FileTextIcon" size="20"
                                                             @click="downloadFile(form[riskQuestion.questionLabel].fileUrl)"/> {{
                      form[riskQuestion.questionLabel].fileName
                    }}</span>
                  <b-button class="btn-icon btn-danger ml-2" @click="removeFile(form[riskQuestion.questionLabel])">
                    <feather-icon icon="Trash2Icon" size="15"/>
                  </b-button>


                </div>
                <div v-if="form[riskQuestion.questionLabel].fileRequired && !form[riskQuestion.questionLabel].fileUrl">
                  <b-form-group
                      description="Only PDF uploads are allowed"
                      class="mb-2"
                  >
                    <b-form-file
                        v-model="form[riskQuestion.questionLabel].file"
                        placeholder="Choose a PDF file..."
                        :class="'fileName-'+riskQuestion.questionLabel"
                        @change="handleFileUpload"
                        accept=".pdf"
                    />
                  </b-form-group>

                </div>
              </div>
            </div>

            <div v-if="riskQuestion.questionType === 'multi_select'">
              <b-form-group class="mb-2">
                <v-select
                    v-model="form[riskQuestion.questionLabel]"
                    :options="riskQuestion.answers"
                    :id="riskQuestion.questionLabel"
                    label="answer"
                    hide-details
                    multiple
                    @input="updateRiskMulti(riskQuestion.questionLabel, riskQuestion.id)"
                >
                </v-select>
              </b-form-group>
            </div>

            <div v-if="riskQuestion.questionType === 'checkbox'">
              <b-form-group class="mb-2">
                <b-form-checkbox
                    v-for="answer in riskQuestion.answers"
                    v-model="form[riskQuestion.questionLabel]"
                    :id="answer.questionLabel"
                    :name="answer.questionLabel"
                    :value="answer.answer"
                    @change="updateRiskMulti(riskQuestion.questionLabel, riskQuestion.id)"
                >
                  {{ answer.answer }}
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div v-if="riskQuestion.questionType === 'text'">
              <b-form-group
                  :label-for="riskQuestion.questionLabel"
                  class="mb-2"
              >
                <b-form-input
                    :id="riskQuestion.questionLabel"
                    v-model="form[riskQuestion.questionLabel].input"
                    @change="updateRiskTextField(riskQuestion.questionLabel, riskQuestion.id)"
                />
                <b-form-checkbox v-for="answer in riskQuestion.answers" class="mt-1"
                    v-if="answer.questionLabel != riskQuestion.questionLabel"
                    v-model="form[riskQuestion.questionLabel]"
                    :id="answer.questionLabel"
                    :name="answer.questionLabel"
                    :value="answer.answer"
                    @change="disableParentTextField(riskQuestion.questionLabel); updateRiskMulti(riskQuestion.questionLabel, riskQuestion.id)"
                >
                {{ answer.answer }}
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div v-if="riskQuestion.questionType === 'number'">
              <b-form-group
                  :label-for="riskQuestion.questionLabel"
                  class="mb-2"
              >
                <cleave
                    :id="riskQuestion.questionLabel"
                    v-model="form[riskQuestion.questionLabel].input"
                    class="form-control"
                    :raw="false"
                    :options="options.number"
                    placeholder="Enter number"
                    @blur="updateRiskTextField(riskQuestion.questionLabel, riskQuestion.id)"
                />
              </b-form-group>
            </div>

            <div v-if="riskQuestion.questionType === 'date'">
              <b-form-group
                  :label-for="riskQuestion.questionLabel"
                  class="mb-2"
              >
                <cleave
                    :id="riskQuestion.questionLabel"
                    v-model="form[riskQuestion.questionLabel].input"
                    class="form-control"
                    :raw="false"
                    :options="options.date"
                    placeholder="DD-MM-YYYY"
                    @blur="updateRiskTextField(riskQuestion.questionLabel, riskQuestion.id)"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BLink,
  BAlert,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import riskService from '@core/services/risk/useRisk'
import helperMixin from "@core/mixins/helpers";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BAlert,
    VBTooltip,
    vSelect,
    Cleave,
  },
  props: ['clientId', 'systemFormId', 'form', 'answers','structure'],
  mixins: [helperMixin],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.getRiskQuestions();
  },
  data() {
    return {
      riskQuestionCategories: [],
      riskQuestions: [],
      files: [],
      risk: 0,
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['d', 'm', 'Y'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  methods: {
    disableParentTextField: function(id) {
      var el = document.getElementById(id);
      if (event.target.checked) {
        el.setAttribute('disabled', true);
      } else {
        el.removeAttribute('disabled');
      }
    },
    getRiskQuestions() {
      riskService.getRiskQuestions({
        'system_form_id': this.systemFormId,
        'client_id': this.clientId
      }).then(response => {
        // let practice = this.practice;
        let that = this;
        this.riskQuestionCategories = response.data.categories;
        this.riskQuestions = response.data.questions;
        this.riskQuestions.forEach(function (question) {
          if (question.questionType === 'checkbox') {
            that.$set(that.form, question.questionLabel, [])
          } else if (question.questionType === 'multi_select') {
            that.$set(that.form, question.questionLabel, null)
          } else {
            that.$set(that.form, question.questionLabel, {input: ""})
          }
        });

        this.$emit('getAnswersReturn', this.riskQuestions);
      });
    },
    updateRisk(selectedAnswer) {
      let answer = eval(`this.form.${selectedAnswer}`);
      console.log(answer);
      let that = this;

      let existingAnswer = this.answers.find(({questionId}) => questionId === answer.questionId);

      if (existingAnswer) {
        this.answers.find((existing, index) => {
          if (existing.questionId === answer.questionId) {
            that.answers[index] = answer;
          }
        });
      } else {
        this.answers.push(answer);
      }

      this.recalculateRisk();
      this.updateProgress();
    },
    updateRiskTextField(selectedAnswer, passedQuestionId) {
      let answer = eval(`this.form.${selectedAnswer}`);
      let that = this;

      let existingAnswer = this.answers.find(({questionId}) => questionId === passedQuestionId);
      let isNew = false;

      if (!existingAnswer) {
        existingAnswer = this.riskQuestions.find(({id}) => id === passedQuestionId);
        isNew = true;
      } else {
        existingAnswer.input = answer.input;
      }

      if (!isNew) {
        this.answers.find((existing, index) => {
          if (existing.questionId === passedQuestionId) {
            that.answers[index].input = answer.input;
          }
        });
      } else if (existingAnswer.answers.length > 0) {
        existingAnswer.answers[0].input = answer.input;
        this.answers.push(existingAnswer.answers[0]);
      }
      this.recalculateRisk();
      this.updateProgress();
    },
    updateRiskMulti(selectedAnswer, passedQuestionId) {
      let passedAnswer = eval(`this.form.${selectedAnswer}`);
      let answers = [];
      let that = this;

      // Convert multi select and checkbox to same array of strings
      if (passedAnswer[0] instanceof Object) {
        passedAnswer.forEach(function (answer) {
          answers.push(answer.answer);
        });
      } else {
        answers = passedAnswer;
      }

      let existingAnswer = this.answers.find(({questionId}) => questionId === passedQuestionId);
      let isNew = false;

      if (!existingAnswer) {
        let existingQuestion = this.riskQuestions.find(({id}) => id === passedQuestionId);
        existingAnswer = existingQuestion.answers[0];

        isNew = true;
      }

      if (!isNew) {
        this.answers.find((existing, index) => {
          if (existing.questionId === passedQuestionId) {
            that.answers[index].selections = answers;
            that.answers[index].input = "";
          }
        });
      } else {
        existingAnswer.selections = answers;
        this.answers.push(existingAnswer);
      }
      this.recalculateRisk();
      this.updateProgress();
    },
    handleFileUpload(event) {
      let uploadName = "";
      let fileClasses = event.target.parentElement.classList;
      fileClasses.forEach(function (fileClass) {
        let splitClasses = fileClass.split('-');
        if (splitClasses[0] === 'fileName') {
          uploadName = splitClasses[1];
        }
      });

      this.files.push({'name': uploadName, 'file': event.target.files[0]});
      this.$emit('updateFilesReturn', this.files);
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    removeFile(question) {
      question.fileUrl = "";
    },
    recalculateRisk() {
      this.risk = riskService.recalculateRisk(this.answers);
    },
    updateProgress() {
      console.log("Updating progress");
      this.$emit('updateProgressReturn', this.answers);
      this.$emit('updateRiskReturn', this.risk);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
