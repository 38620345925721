<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="client-create-wrapper"
  >

    <p>CLIENT PROFILE: This is where details of a client business are entered. (A separate profile is required for each client)<br>
    It covers who they are, the structure of their business, what they do and how your practice engages with them. A short profile is also needed for Beneficial Owners of this client business and for some questions you will be invited to upload copies of relevant documents.</p>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Name"
              label-for="client-name"
              class="mb-2"
          >
            <b-form-input
                id="client-name"
                v-model="client.name"
                @change="saveClientProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Client Structure"
              label-for="client-structure"
              class="mb-2"
          >
            <v-select
                id="client-structure"
                v-model="client.structure"
                :options="structureTypes"
                @change="saveClientProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6"
               v-if="client.structure && (client.structure !== 'Sole Trader' && client.structure !== 'Partnership')">
          <b-form-group
              label="What is the Registration Number of the Limited Company/Partnership?"
              label-for="client-registration-number"
              class="mb-2"
          >
            <b-form-input
                id="client-registration-number"
                v-model="client.registrationNumber"
                @change="saveClientProgress"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <risk-question-block :client-id="this.$route.params.id" :system-form-id="2" :form="form" :answers="client.answers" :structure="client.structure" v-on:updateProgressReturn="updateProgressListener"
                           v-on:updateRiskReturn="updateRiskListener" v-on:getAnswersReturn="getAnswersListener"
                           v-on:updateFilesReturn="updateFilesListener"></risk-question-block>

      <b-row>
        <b-col
            cols="8"
            class="mt-50"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveClient(false)"
          >
            <span v-if="!savingClient">Save Changes</span>
            <span v-if="savingClient"><b-spinner small /> Saving...</span>
          </b-button>
          <b-button
              :disabled="!canComplete || completed"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="!completed ? 'success' : 'flat-success'"
              class="mr-1"
              @click="saveClient(true)"
          >
            <span class="align-middle" v-if="!completed && !savingClient">Complete</span>
            <span class="align-middle" v-if="completed &&  !savingClient"><feather-icon icon="CheckIcon"/> Completed</span>
            <span v-if="savingClient"><b-spinner small /> Saving...</span>
          </b-button>
        </b-col>
        <b-col
            cols="4"
            class="mt-0 text-right"
        >
          <sup>Last updated: {{ client.lastUpdated }}</sup>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->

    <h3>Client Risk: {{ clientRisk }}</h3>
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import clientService from "@core/services/client/useClient";
import RiskQuestionBlock from "../../blocks/RiskQuestionBlock";
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    RiskQuestionBlock,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mounted() {
    if ( this.$route.params.id ){
      this.client.id = this.$route.params.id;
    }
  },
  data() {
    return {
      client: {
        id: 0,
        name: "",
        structure: "",
        registrationNumber: "",
        practiceId: 0,
        lastUpdated: "",
        answers: [],
      },
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['d', 'm', 'Y'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      form: {},
      files: [],
      riskQuestionCategories: [],
      riskQuestions: [],
      structureTypes: ['Sole Trader', 'Partnership', 'Ltd Company', 'Limited Partnership', 'Limited Liability Partnership', 'Other'],
      clientRisk: 0,
      canComplete: false,
      completed: false,
      savingClient: false,
      pageLoaded: false
    }
  },
  methods: {
    getAnswersListener(value) {
      this.riskQuestions = value;
      this.getClient();
    },
    getClient() {
      if (this.$route.params.id) {
        clientService.getClient({
          id: this.$route.params.id
        }).then(response => {
          this.client = response.data;
          this.clientRisk = this.client.totalRisk;
          this.canComplete = this.client.isComplete;
          this.completed = this.client.completed;
          let that = this;

          if (this.client.answers.length > 0) {
            this.client.answers.forEach(function (answer) {
              if (answer.questionType === 'checkbox' || (answer.questionType === 'text' && answer.selections != null && answer.selections.length > 0)) {
                // either a checkbox question or nested text field with checkbox option
                that.$set(that.form, answer.questionLabel, answer.selections);
                var el = document.getElementById(answer.questionLabel);
                // disable parent text field on page load
                if (el != null && el.type == 'text') {
                   el.setAttribute('disabled', true);
                }
              } else if (answer.questionType === 'multi_select') {
                let question = that.riskQuestions.find(({id}) => id === answer.questionId);
                let previousSelections = [];

                answer.selections.forEach(function (selection) {
                  question.answers.forEach(function (answer) {
                    if (answer.answer === selection) {
                      previousSelections.push(answer);
                    }
                  });
                });

                that.$set(that.form, answer.questionLabel, previousSelections)
              } else {
                that.$set(that.form, answer.questionLabel, answer)
              }
            });
          }

          this.pageLoaded = true;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }else{
        this.pageLoaded = true;
      }
    },
    saveClientProgress() {
      let userData = JSON.parse(localStorage.getItem('userData'));

      this.client.practiceId = userData.practiceId;

      let data = new FormData();
      data.append('client', JSON.stringify(this.client));
      if (this.client.id) {
        data.append('id', this.client.id);
      }
      data.append('complete', 'false');
      if (this.files.length > 0) {
        this.files.forEach(function (file) {
          data.append(file.name, file.file);
        });
      }

      if (this.client.id) {
        clientService.updateClient(data).then(response => {
          this.canComplete = response.data.complete;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      } else {
        clientService.createClient(data).then(response => {
          if (response.data.id) {
            this.client.id = response.data.id;
          }
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    saveClient(complete) {
      this.savingClient = true;
      let userData = JSON.parse(localStorage.getItem('userData'));

      this.client.practiceId = userData.practiceId;

      let data = new FormData();
      data.append('client', JSON.stringify(this.client));
      if (this.client.id) {
        data.append('id', this.client.id);
      }
      data.append('complete', complete);
      if (this.files.length > 0) {
        this.files.forEach(function (file) {
          data.append(file.name, file.file);
        });
      }

      if (this.client.id) {
        clientService.updateClient(data).then(response => {
          if ( response.data.errors ){
            this.showErrorMessage(response.data.errors);
          }else{
            this.showSuccessMessage("You have successfully updated this client");

            if ( complete && !this.completed ){
              this.completed = true;
            }
          }
          this.savingClient = false;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      } else {
        clientService.createClient(data).then(response => {
          if ( response.data.errors ){
            this.showErrorMessage("Something went wrong!");
          }else{
            this.showSuccessMessage("You have successfully created a new client");
            window.location.href = '/client/' + response.data.id;
          }
          this.savingClient = false;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    updateProgressListener(value) {
      this.client.answers = value;
      console.log(this.client.answers);
      this.saveClientProgress();
    },
    updateFilesListener(value) {
      this.files = value;
    },
    updateRiskListener(value) {
      this.clientRisk = value;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
