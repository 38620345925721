const helperMixin = {
    data() {
        return {
            // countDown: 10,
            // interval: null,
        };
    },
    methods: {
        kebabToCamel: function(string) {
            let array = string.split('-');
            let camel = "";
            let that = this;

            array.forEach(function (element, index) {
                if (index > 0) {
                    element = that.capitalizeFirst(element);
                }
                camel += element;
            });

            return camel;
        },
        capitalizeFirst: function(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
    mounted: function () {
        // this.interval = setInterval(this.decrement, 1000);
    },
    unmounted: function () {
        // clearInterval(this.interval);
    },
};

export default helperMixin;