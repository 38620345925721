import BaseService from "@core/services/baseService";

export default class ClientService extends BaseService{
    createClient(...args){
        return this.axiosIns.post(this.formConfig.clientEndpoint,...args)
    }
    updateClient(...args){
        return this.axiosIns.post(this.formConfig.clientEndpoint+"/edit/"+args[0].get('id'),...args)
    }
    getClient(...args){
        return this.axiosIns.get(this.formConfig.clientEndpoint+"/"+args[0].id,...args)
    }
    deleteClient(...args){
        return this.axiosIns.delete(this.formConfig.clientEndpoint+"/"+args[0].id)
    }
    importClient(...args){
        return this.axiosIns.post(this.formConfig.importClientEndpoint,...args)
    }
    getByPractice(...args){
        return this.axiosIns.get(this.formConfig.clientEndpoint,{params: {'practice_id': args[0].practice_id}})
    }
}